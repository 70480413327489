import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class NavigationController {
    constructor() {
        this.container = null;
        this.mobileNavigationContainer = null;
        this.mobileNavToggle = null;
        this.scrollTimeout = null;
        this.scrollableElements = null;

        this.tolerance = 5; // the amount scrolled in one event before triggering pinning, used to stop flickering navbar on smooth scroll
        this.pinnedOffset = 50; // vertical offset in px before element is first unpinned
        this.topClass = 'is-top'; // class added to element when above offset
        this.notTopClass = 'is-not-top'; // class added to element when below offset
        this.pinnedClass = 'is-pinned'; // class added to element when pinned
        this.unpinnedClass = 'is-unpinned'; // class added to element when unpinned
        this.frozen = false; // freezes pin and unpin class changes

        this.isMobileNavOpen = false;
        this.lastKnownScrollY = 0; // holds last known scroll position, used for pinning or unpinning the element
    }

    init() {
        this.container = $('header.site-header');
        this.mobileNavigationContainer = $('.main-nav.mobile-nav');
        this.mobileNavToggle = $('.overlay-toggle-container', this.mobileNavigationContainer);
        this.scrollableElements = $('.main-menu-overlay .main-menu-content', this.mobileNavigationContainer);

        //$(window).on('scroll.bws-navigation-controller', (event) => this.onScroll(event));
        this.mobileNavToggle.on('click.bws-navigation-controller', (event) => this.toggleMobileNav(event));

        $('.menu-item.is-parent .dropdown-parent .dropdown-trigger', this.mobileNavigationContainer).on('click.bws-navigation-controller', (event) => this.toggleMobileNavDropdown(event));

        $('.search-button-links .search-button', this.container).on('click.bws-navigation-controller', (event) => {
            event.preventDefault();
            $('.search-overlay', this.container).removeClass('hidden');
            $('.search-overlay .search-input', this.container).get(0).focus();
        });

        $('.search-overlay .search-close-button', this.container).on('click.bws-navigation-controller', event => {
            event.preventDefault();
            $('.search-overlay', this.container).addClass('hidden');
        });

        //this.onScroll(null); // trigger event once to add initial classes
    }

    onScroll(event) {
        // If there's a scroll timer already existing, cancel it
        if (this.scrollTimeout) {
            window.cancelAnimationFrame(this.scrollTimeout);
        }

        // Setup the new requestAnimationFrame
        this.scrollTimeout = window.requestAnimationFrame(() => {
            let currentScrollY = $(document).scrollTop();
            let scrollDirection = currentScrollY > this.lastKnownScrollY ? 'down' : 'up';

            // set top or not top based on current scroll position and offset
            if (currentScrollY >= this.pinnedOffset) {
                this.setNotTop();
            } else {
                this.setTop();
            }

            // set pinned or not based on distance scrolled and direction
            if (scrollDirection === 'down' && currentScrollY >= this.lastKnownScrollY && Math.abs(currentScrollY - this.lastKnownScrollY) >= this.tolerance) {
                this.setUnpinned();
            } else if (scrollDirection === 'up' && currentScrollY <= this.lastKnownScrollY && Math.abs(currentScrollY - this.lastKnownScrollY) >= this.tolerance) {
                this.setPinned();
            }

            this.lastKnownScrollY = currentScrollY;
        });
    }

    toggleMobileNav(event) {
        if (this.isMobileNavOpen) {
            $('.hamburger', this.mobileNavToggle).removeClass('is-active');
            $('.page-wrapper').removeClass('mobile-nav-active');
            $('.main-menu-overlay', this.mobileNavigationContainer).removeClass('is-active');

            // $('ul.menu .menu-item a', this.mobileNavigationContainer).off('click.bws-navigation-controller');
            enableBodyScroll(this.scrollableElements);
            this.frozen = false;
        } else {
            $('.hamburger', this.mobileNavToggle).addClass('is-active');
            $('.page-wrapper').addClass('mobile-nav-active');
            $('.main-menu-overlay', this.mobileNavigationContainer).addClass('is-active');

            // $('ul.menu .menu-item a', this.mobileNavigationContainer).on('click.bws-navigation-controller', (event) => this.toggleMobileNav(event));
            disableBodyScroll(this.scrollableElements);
            this.frozen = true;
        }

        this.isMobileNavOpen = !this.isMobileNavOpen;
    }

    toggleMobileNavDropdown(event) {
        let dropdownTrigger = $(event.currentTarget);
        let dropdownContainer = dropdownTrigger.parents('.menu-item.is-parent');
        
        if (dropdownContainer.hasClass('is-open')) {
            dropdownContainer.removeClass('is-open');
        } else {
            dropdownContainer.addClass('is-open');
        }
    }

    freezePinning() {
        this.frozen = true;
    }

    unfreezePinning() {
        this.frozen = false;
    }

    setTop() {
        if (this.mobileNavigationContainer.hasClass(this.notTopClass)) {
            this.mobileNavigationContainer.removeClass(this.notTopClass);
        }

        if (!this.mobileNavigationContainer.hasClass(this.topClass)) {
            this.mobileNavigationContainer.addClass(this.topClass);
        }
    }

    setNotTop() {
        if (this.mobileNavigationContainer.hasClass(this.topClass)) {
            this.mobileNavigationContainer.removeClass(this.topClass);
        }

        if (!this.mobileNavigationContainer.hasClass(this.notTopClass)) {
            this.mobileNavigationContainer.addClass(this.notTopClass);
        }
    }

    setPinned() {
        if (!this.frozen) {
            if (this.mobileNavigationContainer.hasClass(this.unpinnedClass)) {
                this.mobileNavigationContainer.removeClass(this.unpinnedClass);
            }

            if (!this.mobileNavigationContainer.hasClass(this.pinnedClass)) {
                this.mobileNavigationContainer.addClass(this.pinnedClass);
            }
        }
    }

    setUnpinned() {
        if (!this.frozen) {
            if (this.mobileNavigationContainer.hasClass(this.pinnedClass)) {
                this.mobileNavigationContainer.removeClass(this.pinnedClass);
            }

            if (!this.mobileNavigationContainer.hasClass(this.unpinnedClass)) {
                this.mobileNavigationContainer.addClass(this.unpinnedClass);
            }
        }
    }

    destroy() {
        //window.removeEventListener('scroll', this.onScroll);
        this.mobileNavToggle.off('click.bws-navigation-controller');
        $('.menu-item.is-parent .dropdown-parent .dropdown-trigger', this.mobileNavigationContainer).off('click.bws-navigation-controller');
        $('.search-button-links .search-button', this.container).off('click.bws-navigation-controller');
        $('.search-overlay .search-close-button', this.container).off('click.bws-navigation-controller');
        //$('ul.menu .menu-item a', this.mobileNavigationContainer).off('click.bws-navigation-controller');
        clearAllBodyScrollLocks();

        this.mobileNavToggle = null;
        this.scrollTimeout = null;
        this.mobileNavigationContainer = null;
        this.lastKnownScrollY = 0;
        this.scrollableElements = null;
    }
}

export default NavigationController;