import ScrollMonitor from 'scrollmonitor';
//import { Foundation } from 'foundation-sites/js/foundation.core';
// NOTE: Foundation scripts break IE11 and down, so don't use them.

import NavigationController from './NavigationController';

class MainController {
    // main class initialization - runs once on site load
    constructor() {
        // Create class variables
        this.scrollMonitor = ScrollMonitor;
        //Foundation.addToJquery($);
        this.scrollWatchers = [];
        this.navigationController = null;
        this.freeformController = null;
        this.calendarController = null;

        // hide preloader 
        $('.revealer').removeClass('show').addClass('animate-out');
        setTimeout(function () {
            $('.revealer').removeClass('animate-out');
        }, 600);
    }

    // initialization - runs each time a new page is loaded
    init() {
        try {
            //$(document).foundation();
            $('body').addClass('animations-enabled');
            var _instance = this;

            if (typeof ga === 'function') {
                ga('send', 'pageview', location.pathname);
            }

            // initialize navigation controller
            this.navigationController = new NavigationController();
            this.navigationController.init();

            // initialize calendar controller 
            if ($(".calendar-section").length) {
                import(/* webpackChunkName: "calendar" */ './CalendarController').then(({ default: CalendarController }) => {
                    this.calendarController = new CalendarController();
                    this.calendarController.init();
                });
            }

            // initialize freeform controller
            if ($('.section-form').length) {
                import(/* webpackChunkName: 'freeform' */ './FreeformController').then(({ default: FreeformController }) => {
                    this.freeformController = new FreeformController();
                    this.freeformController.init();
                });
            }

            // run element animations when in viewport (adds .is-active to visible elements as you scroll)
            $('.animatable').each(function (index) {
                // create two watchers - one with an offset for enter events, and one without an offset for exit events
                // lets us add visibility classes with an offset, and remove them when the element is completely outside the visible viewport
                let enterScrollWatcher = scrollMonitor.create($(this).get(0), -100);
                let exitScrollWatcher = scrollMonitor.create($(this).get(0), 100);
                _instance.scrollWatchers.push(enterScrollWatcher);
                _instance.scrollWatchers.push(exitScrollWatcher);


                enterScrollWatcher.enterViewport(function () {
                    $(this.watchItem).addClass('is-active');
                });

                exitScrollWatcher.exitViewport(function () {
                    $(this.watchItem).removeClass('is-active');
                });

                // If any of the elements are visible, add the active class (after an initial delay, to facilitate page transition animation)
                $(this).addClass('will-animate');
                $(this).removeClass('is-active');

                if (exitScrollWatcher.isInViewport) {
                    setTimeout(() => {
                        $(exitScrollWatcher.watchItem).addClass('is-active');
                    }, 50);
                }
            });
            
            // Accordion content block scripts
            $('.section-accordion .accordion-item .accordion-heading').on('click.bws-nav', function (e) {
                let accordionItem = $(this).parent();

                if (accordionItem.hasClass('open')) {
                    accordionItem.removeClass('open');
                } else {
                    accordionItem.addClass('open');
                }
            });

            // mobile sidebar accordions
            $('.sidebar .sidebar-heading.mobile-sidebar-heading').on('click.bws-sidebar', function(e) {
                let sidebarAccordion = $(this).parent();

                if (sidebarAccordion.hasClass('open')) {
                    sidebarAccordion.removeClass('open');
                } else {
                    sidebarAccordion.addClass('open');
                }
            });

            // mobile footer accordions
            $('.footer-navigation .nav-columns .nav-column-heading').on('click.bws-footer', function(e) {
                let footerAccordion = $(this).parent();

                if (footerAccordion.hasClass('open')) {
                    footerAccordion.removeClass('open');
                } else {
                    footerAccordion.addClass('open');
                }
            });

            // Business category select 
            if ($('.business-index-single .category-filter-container .category-filter-select').length) {
                $('.business-index-single .category-filter-container .category-filter-select').on('change.bws-filter', function(e) {
                    if (window.H) {
                        window.H.redirect(encodeURI($(this).val()));
                    } else {
                        window.location.href = encodeURI($(this).val());
                    }
                });
            }

            // Search form submission
            $('.search-component form.search-form').on('submit.bws-search-component', function(e) {
                e.preventDefault();
                let data = $(this).serialize();

                if (window.H) {
                    window.H.redirect(window.baseUrl + 'search?' + data);
                } else {
                    window.location.href = '/search?' + data;
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    // Runs every time a new page replaces the current one
    // Destroys all scripts on the page, so they can be reinitialized on the new one
    destroy() {
        try {
            // destroy navigation controller
            this.navigationController.destroy();
            this.navigationController = null;

            if (this.calendarController) {
                this.calendarController.destroy();
                this.calendarController = null;
            }

            if (this.freeformController) {
                this.freeformController.destroy();
                this.freeformController = null;
            }

            // destroy element animation scroll watchers 
            for (let i = 0; i < this.scrollWatchers.length; i++) {
                this.scrollWatchers[i].destroy();
            }

            $('.section-accordion .accordion-item .accordion-heading').off('click.bws-nav');
            $('.sidebar .sidebar-heading.mobile-sidebar-heading').off('click.bws-sidebar');
            $('.footer-navigation .nav-columns .nav-column-heading').off('click.bws-footer');
            $('.business-index-single .category-filter-container .category-filter-select').off('change.bws-filter');
            $('.search-component form.search-form').off('submit.bws-search-component');

            this.scrollWatchers = [];
            //$(document).foundation('destroy');
        } catch (e) {
            console.log(e);
        }
    }
}

export default MainController;